<template>
	<div id="warper">
		<el-card class="center">
			<div class="payment">
				<div
					style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;margin-top: 30px;font-weight: bold;margin-bottom: 14px;">
					订单编号：{{orderno}}</div>
				<div
					style="display: flex;padding-bottom: 23px;border-bottom: 1px solid #eee;align-items: center;justify-content: space-between;">
					<div style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;font-weight: bold;">
						支付金额：<span style="font-size: 20px;color:#7ECE64 ;">¥{{orderinfo.payMoney}}</span></div>
					<!-- <div style="">
						<el-dropdown>
							<span class="el-dropdown-link">
								订单详情<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>黄金糕</el-dropdown-item>
								<el-dropdown-item>狮子头</el-dropdown-item>
								<el-dropdown-item>螺蛳粉</el-dropdown-item>
								<el-dropdown-item disabled>双皮奶</el-dropdown-item>
								<el-dropdown-item divided>蚵仔煎</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<div style="margin-left: 20px;">
						<el-dropdown>
							<span class="el-dropdown-link">
								用户详情<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>黄金糕</el-dropdown-item>
								<el-dropdown-item>狮子头</el-dropdown-item>
								<el-dropdown-item>螺蛳粉</el-dropdown-item>
								<el-dropdown-item disabled>双皮奶</el-dropdown-item>
								<el-dropdown-item divided>蚵仔煎</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div> -->
				</div>
				<!--<div v-if="orderinfo.payState==2">
					<div class="info">
						<strong>订单已支付成功！</strong>
						<p>您可以点击这里进入<router-link to="usercenter?type=0">会员中心</router-link>查看订单状态！</p>
						<p>如有其它问题，请立即与我们客服人员联系。</p>
					</div>
				</div>
				<div v-else> -->
				<div>
					<div class="paytitle"
						style="color: #101010;font-size: 14px;font-family: SourceHanSansSC-regular;margin-top: 38px;font-weight: bold;margin-bottom: 14px;">
						支付方式</div>
					<div class="wxzf">
						<img src="../../assets/img/wx.png"
							style="width: 24px;height: 24px;margin-left: 24px;margin-top: 8px;margin-right: 4px;" />
						<div>微信支付</div>
						<img src="../../assets/img/sanj.png"
							style="width: 20px;height: 21px;position: absolute;right: 0;bottom: 0;" />
						<img src="../../assets/img/diegou.png"
							style="width: 10px;height: 10px;position: absolute;right: 2px;bottom: 2px;" />
					</div>
					<div style="display: flex;margin-top: 14px;">
						<div style="text-align: center;">
							<img :src="payimg" style="width: 200px;height: 200px;" />
							<div
								style="background-color: #62C442;color: #fff;height: 28px;line-height: 28px;text-align: center;">
								请打开手机微信，扫一扫完成支付</div>
						</div>
						<img src="../../assets/img/shouji.png" style="width: 206px;height: 255px;" />
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		getCourseDetail,
		getTrainingOrder
	} from "@/api/home";
	import {
		getwxpay,
		getwxpaylj,
		payOrderlj,

	} from "@/api/other";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer.vue";
	import global from "@/assets/js/globalconfig";
	import axios from "axios";
	export default {
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				baseurl: global.baseurl,
				orderno: "",
				userinfo: "",
				info: {},
				orderinfo: {},
				payimg: '',
				timers: ''
			};
		},
		created() {
			this.orderno = this.$route.query.orderno;
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			this.getTrainingOrder()
			this.timers = setInterval(() => {
				//this.getTrainingOrder();
			}, 1000);
		},
		methods: {
			pay() {
				//预下单
				var _this = this;
				var params = new URLSearchParams();
				params.append('out_trade_no', _this.orderno);
				debugger;
				payOrderlj(params).then( (res) => {
					return res.data;
				})
			},
			getTrainingOrder() {
				var _this = this;
				var par = {
					KW: this.orderno
				};
				getTrainingOrder(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0) {
							_this.orderinfo = res.data[0];
							_this.getCourseDetail(_this.orderinfo.course_id);
							if (_this.orderinfo.payState == 2) {
								clearInterval(_this.timers)
								_this.goto("usercenter", {
									type: 0
								}, 1);
								// _this.goto('/trainingDetails', {
								// 	id: _this.orderinfo.course_id
								// }, 1);
							}
						}
					}
				})
			},
			getCourseDetail(id) {
				var _this = this;
				var par = {
					id: id
				};
				getCourseDetail(par).then((res) => {
					if (res.success) {
						_this.info = res.data[0];
						if (_this.payimg == '' && _this.orderinfo.payState <2) {
							_this.getwxpay()
						}
					}
				})
			},
			getwxpay() {
				var _this = this;
				if (_this.pay() == "SUCCESS") {
					//跳转到支付完成页面
					//_this.orderinfo.payState = 2;
					// _this.goto("usercenter", {
					// 	type: 0
					// }, 1);
				} else {
					if (_this.payimg == '') {
						var params = new URLSearchParams();
						params.append('out_trade_no', _this.orderno);
						params.append('total', _this.orderinfo.payMoney);
						params.append('notify_url', this.baseurl + '/api/WxPayNotify/Transactions?orderno=' + _this
							.orderno);
						if (_this.info.leverid == 1) {
							params.append('description', "兰溪人才网-课程培训-" + _this.info.course_name);
							getwxpaylj(params).then((res) => {
								_this.payimg = res.data;
							})
						} else {
							params.append('description', "兰溪人才网-招考报名-" + _this.info.course_name);
							getwxpay(params).then((res) => {
								_this.payimg = res.data;
							})
						}
					}
				}
			},
			onSubmit() {
				console.log('submit!');
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.center {
		width: 1200px;
		margin: 29px auto;
		height: 863px;


		.payment {
			width: 529px;
			margin: 0 auto;




			.wxzf {
				width: 135px;
				height: 40px;
				line-height: 40px;
				display: flex;
				align-content: center;
				text-align: center;
				border: 1px solid #409EFF;
				position: relative;
			}
		}
	}
</style>
